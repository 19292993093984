
/* root colors defined in style */
body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
h1{ color:var(--background-color); }
table {
  min-width: 600px;
}

#tc-widget-root{
  position:absolute; z-index:9999;
}

tc-root[data-tc-dropdown-container="true"] {
  position: absolute;
  z-index: 9999 !important;
  left: auto !important;
}
#ton-connect-button{
  margin: 0 auto;
}
th,
td { 
  border: 1px solid #ddd;
}

tr:hover td {
  background: #fff;
}

thead th {
  background-color: #f2f2f2;
}

@media screen and (max-width: 440px) {
  .login-container {
    display: flex;
    flex-direction: column;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.scroll-counter {
  position: fixed;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

@keyframes expandAndColorChange {
  0% {
    transform: scale(1);
    color: white;
  }
  50% {
    transform: scale(1.5);
    color: yellow;
  }
  100% {
    transform: scale(1);
    color: white;
  }
}

.scroll-counter.animate .distance {
  display:inline-block;
  animation: expandAndColorChange 0.3s ease-in-out;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.image-item {
  position: relative;
  margin-bottom: 30px;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.image-item.fade-in {
  opacity: 1;
}

.image-item img {
  width: 100%;
  display: block;
}
.image-item .content {
  top: 0;
  left: 0;
  opacity:0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.image-item.swiped .content {

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: left 0.3s ease;
}
.image-item.swiped .content h1{
  color: white;
}


/* Responsive styling */
@media (max-width: 1100px) {
  .my-masonry-grid_column {
    padding-left: 20px; /* gutter size */
  }
  .image-item {
    margin-bottom: 20px;
  }
}

@media (max-width: 700px) {
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
  }
  .image-item {
    margin-bottom: 10px;
  }
}

/* Styles for desktop and larger screens */
@media screen and (min-width: 1200px) {
  /* Add your CSS rules here */
}

/* Styles for tablet-sized screens */
@media screen and (min-width: 440px) and (max-width: 1199px) {
  /* Add your CSS rules here */
  .templatedetails {
    display: none;
  }
}

/* Styles for mobile and smaller screens */
@media screen and (max-width: 440px) {
  /* Add your CSS rules here */
  .config > div {
    max-width: 100%;
    min-width: 0;
    margin: 0;
    display: block;
  }
}